import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Button, ButtonGroup } from 'reactstrap'

import Layout from './../components/Layout'

export class blog extends Component {
  render() {
    const allPosts = this.props.data.allMarkdownRemark.edges

    return (
      <div>
        <Helmet>
          <title>mHotspot Blog</title>
        </Helmet>
        <Layout>
          {allPosts.map(p => {
            const { title, permalink, guid, categories } = p.node.frontmatter
            const { excerpt } = p.node

            if (guid && guid.indexOf('mhotspot.com/blog') > -1) {
              return (
                <div key={title} style={{ width: '70%', minWidth: 350 }}>
                  <Link to={`blog/${permalink}`}>
                    <h5>{title}</h5>
                  </Link>
                  {excerpt}
                  <br />
                  {categories && (
                    <div style={{ marginTop: 10 }}>
                      <ButtonGroup>
                        {categories.map(c => (
                          <Button
                            outline
                            disabled
                            color="secondary"
                            key={c}
                            size="sm"
                          >
                            {c}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                </div>
              )
            }
          })}
        </Layout>
      </div>
    )
  }
}

export default blog

export const blogQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 50
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            permalink
            guid
            categories
          }
        }
      }
    }
  }
`
